import { _axios } from '@/utils/axios'

export function getInvitationInfo(params) {
  if (params) {
    params.random = Math.random()
  }
  // 获取邀请码相关的信息
  return _axios({
    url: '/carpool/ns/invitation',
    method: 'get',
    params,
  })
}

export function invitationCodeCheck(params) {
  // 获取邀请码相关的信息
  return _axios({
    url: '/carpool/ns/invitation/check',
    method: 'get',
    params,
  })
}

export function withdraw(data) {
  // 提现
  return _axios({
    url: '/carpool/ns/invitation/bounty/withdraw',
    method: 'post',
    data,
  })
}
