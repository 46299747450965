<template>
  <div class="invitation" :style="styleCustom">
    <div class="invitation-content-wrap">
      <div class="invitation-content">
        <p class="invitation-text" v-html="content"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'invitation',
  props: {
    styleCustom: {
      type: Object
    }
  },
  data () {
    return {
      content: `注意：2023年11月01日 后有新加入过其他家庭的账号，无法再加入新家庭，请换个账号下单哦！即使能加入家庭，也将可能失去会员（这种情况<span style="color:red;font-weight:bold;">不退款</span>）。`
    }
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.invitation {
  width: 7.5rem;
  height: 0.9rem;
  min-height: 0.78rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #fff1c0;
  white-space: nowrap;
  .invitation-content-wrap {
    width: 7.02rem;
    overflow: hidden;
    color: #a96c3d;
    .invitation-content {
      display: flex;
      align-items: center;
      .invitation-text {
        display: inline-block;
        font-size: 0.24rem;
        &.padding {
          padding-right: 6.54rem;
        }
      }
    }
  }
}
</style>
