<template>
  <div class="shangche">
    <PaymentWarning
      ref="warning"
      :checkedIdx="checkedIdx"
      :tooltipShow="paymentTooltipShow"
      @check="paymentCheck"
    ></PaymentWarning>
    <div class="car-form">
      <div class="product">
        <img src="../../../assets/product.png" />
        <div class="sku">
          <div class="name">商品名称：{{ nameShowMap[plan.stName] }}</div>
          <div class="duration">
            {{ '时长：' + plan.name }}
          </div>
        </div>
      </div>
      <div class="input-item">
        <div class="title-star">
          {{
            plan.stName.indexOf('包车') !== -1 ? '司机NS绑定邮箱' : 'NS绑定邮箱'
          }}
        </div>
        <input
          v-model="order.email"
          placeholder="任天堂账号绑定的邮箱(注意大小写)"
        />
      </div>
      <div class="input-item">
        <div class="title-star">确认邮箱</div>
        <input
          onpaste="return false"
          v-model="order.repeatEmail"
          placeholder="确认任天堂账号所绑定的邮箱"
        />
      </div>
      <div class="input-item" v-if="!isWeixin">
        <div class="title-star">联系方式</div>
        <input v-model="contact" placeholder="用于查询订单，建议使用手机号" />
      </div>
      <div
        class="input-item"
        style="border-bottom: 0"
        v-if="plan.stName.indexOf('芝麻车') !== -1"
      >
        <div class="title-star">芝麻信用分截图</div>
        <div class="screenshot-demo" @click="zhimademo">截图示例</div>
      </div>
      <div class="zhimafen-upload" v-if="plan.stName.indexOf('芝麻车') !== -1">
        <input
          v-if="!zhimafenImgUrl && !isWeixin"
          type="file"
          accept="image/*"
          ref="image"
          style="display: none"
          @change="handleInputChange"
        />
        <div
          class="upload-default"
          @click="uploadImageHandle"
          v-if="!zhimafenImgUrl"
        >
          +
        </div>
        <div class="zhimafen-wrap" v-if="zhimafenImgUrl">
          <img :src="zhimafenImgUrl" @click="zhimafenPreview" />
          <div class="zhimafen-close" @click="delZhimafen"></div>
        </div>
      </div>
      <div class="input-item">
        <div class="title">
          邀请码
          <svg
            t="1668259122533"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="6959"
            width="0.28rem"
            height="0.28rem"
            @click="invitaionInfoShow = true"
          >
            <path
              d="M512 149.333333c200.298667 0 362.666667 162.368 362.666667 362.666667s-162.368 362.666667-362.666667 362.666667S149.333333 712.298667 149.333333 512 311.701333 149.333333 512 149.333333z m0 64c-164.949333 0-298.666667 133.717333-298.666667 298.666667s133.717333 298.666667 298.666667 298.666667 298.666667-133.717333 298.666667-298.666667-133.717333-298.666667-298.666667-298.666667z m39.658667 426.666667v64h-59.946667l-0.021333-64h59.968z m-3.541334-316.757333c38.72 7.808 84.778667 44.736 84.778667 98.453333 0 53.738667-43.882667 74.069333-59.605333 84.117333-14.506667 9.28-20.672 19.882667-21.525334 31.189334l-0.106666 2.816V597.333333h-59.968v-79.338666c0-18.816 6.037333-32 22.826666-43.648l3.776-2.496 33.685334-22.4c21.482667-14.464 21.184-43.498667 7.893333-56.32a60.373333 60.373333 0 0 0-52.842667-13.546667c-37.376 7.338667-41.685333 33.706667-41.941333 59.306667v14.485333H405.333333c0-49.834667 5.717333-72.426667 32.298667-100.970667 29.781333-31.893333 71.744-37.013333 110.485333-29.162666z"
              p-id="6960"
            ></path>
          </svg>
        </div>
        <input
          v-if="!this.invitation.checked"
          v-model="invitation.code"
          @blur="invitationCodeCheck"
          placeholder="区分大小写噢"
        />
        <div class="coupon-discount have" v-else>
          {{ '-￥' + this.invitation.discountAmount.toFixed(2) }}
        </div>
      </div>
      <div class="input-item">
        <div class="title">优惠券</div>
        <div class="coupon-info" @click="couponShow = true">
          <div class="coupon-discount" v-if="!selectCoupon.couponId">
            {{
              allCoupons.filter(coupon => {
                return (
                  coupon.skus.filter(sku => sku.id == this.order.skuId)
                    .length &&
                  new Date(coupon.userCoupon.expiredAt) >= new Date()
                )
              }).length
            }}
            张可用
          </div>
          <div class="coupon-discount have" v-else>
            {{
              '-￥' +
              (this.order.price * (10 - selectCoupon.discount) * 0.1).toFixed(2)
            }}
          </div>

          <div class="coupon-more"></div>
        </div>
      </div>
      <Payment
        @methodTab="paymentMethodTab"
        @getOrderInfo="getOrderInfo"
        :showBrowserGuide="showBrowserGuide"
        :method="payMethod"
      ></Payment>
    </div>
    <div class="footer">
      <div class="pay">
        <div class="pay-amount-agreement">
          <div class="pay-amount">
            <span class="label">支付金额：</span>
            <span class="currency">￥</span>
            <span class="amount">{{
              selectCoupon.discount
                ? (plan.originPrice * selectCoupon.discount * 0.1).toFixed(2)
                : invitation.checked
                ? (plan.originPrice - invitation.discountAmount).toFixed(2)
                : plan.price
            }}</span>
          </div>
          <div class="agreement">
            点击支付即表示同意<span
              @click="agreementShow = true"
              style="color: #333333"
              >《会员服务协议》</span
            >
          </div>
        </div>

        <button
          class="pay-button"
          :class="{ ex: plan.stName.indexOf('高级') !== -1 }"
          @click="submitOrder"
        >
          支付
        </button>
      </div>
    </div>
    <div class="invitation-mask" v-if="invitaionInfoShow">
      <div class="invitation-box">
        <div class="question">邀请码可以做什么</div>
        <div class="answer">
          如果你是平台的「新用户」，在填入邀请码后，好友可获得赏金，你可以获得立减金额「1.5~2.5元不等」
        </div>
        <div class="question">怎么获得邀请码</div>
        <div class="answer">
          在社交平台或者向我们平台开通年费会员的朋友索要邀请码
        </div>
        <div class="tips">注意：如果没有邀请码也可以不填写</div>
        <div class="footer-btn" @click="invitaionInfoShow = false">知道了</div>
      </div>
    </div>

    <Toast
      :showToast="showToast"
      :message="toastMsg"
      @disappear="showToast = false"
    ></Toast>
    <Loading :display="showLoading"></Loading>
    <confirm
      :visible="confirmVisible"
      @cancel="confirmCancel"
      :confirmText="confirmText"
      :cancelText="cancelText"
      @confirm="confirm"
    >
      <div v-if="confirmType == 1" class="confirm-body">
        请确认支付是否已完成？
      </div>
      <div class="confirm-body" v-else>
        <div class="dialog-body-row">
          <div>确认要放弃付款？</div>
        </div>
        <div class="dialog-body-row">
          <div class="rights">
            <div class="right">
              <img src="../../../assets/rocket.svg" />
              <div>极速发车</div>
            </div>
            <div class="right">
              <img src="../../../assets/shield.svg" />
              <div>信誉保障</div>
            </div>
          </div>
        </div>
      </div>
    </confirm>
    <Zhimafen
      :display="zhimafenDisplay"
      @close="zhimafenDisplay = false"
      :url="zhimafenImgUrl"
    ></Zhimafen>
    <Zhimafen
      :display="demoDisplay"
      @close="demoDisplay = false"
      :url="demoUrl"
      :type="'demo'"
    ></Zhimafen>
    <WechatQR :visible="qrVisible" @cancel="qrVisible = false"></WechatQR>
    <ServiceAgreement
      @close="agreementShow = false"
      :visible="agreementShow"
    ></ServiceAgreement>

    <Coupon
      :display="couponShow"
      :coupons="coupons"
      @couponStatusChange="couponStatusChange"
      @close="couponClose"
      @confirm="couponConfirm"
    ></Coupon>
  </div>
</template>
<script>
/* eslint-disable */
import { purchase, getOrderInfo } from '@/api/carpool/order'
import { getCoupons } from '@/api/user'
import { getJsapiSign } from '@/api/common'
import Toast from '@/components/Toast'
import Loading from '@/components/Loading'
import Confirm from '@/components/Confirm'
import Zhimafen from '@/components/Zhimafen'
import WechatQR from '@/components/WechatQR'
import Payment from '@/components/Payment'
import debounce from '@/utils/debounce'
import ServiceAgreement from '@/components/ServiceAgreement'
import PaymentWarning from '@/components/PaymentWarning'
import PaymentInvitation from '@/components/PaymentInvitation'
import Coupon from '@/components/Coupon'
import { reportError } from '@/utils/log'
import wx from 'weixin-js-sdk'
import { utils } from '@/utils'
import { invitationCodeCheck } from '@/api/carpool/invitaion'

export default {
  name: 'Home',
  data() {
    return {
      couponShow: false,
      payMethod: 'wechatPay',
      invitation: {
        code: '',
        checked: false,
        discountAmount: 0
      },
      invitationCodeChecked: false,
      nameShowMap: {
        快捷车: 'NS普通家庭会员',
        芝麻车: 'NS普通家庭会员',
        包车: 'NS普通家庭会员（包车）',
        高级快捷车: 'NS高级家庭会员',
        高级芝麻车: 'NS高级家庭会员',
        高级包车: 'NS高级家庭会员（包车）'
      },
      agreementShow: false,
      invitaionInfoShow: false,
      showLoading: false,
      confirmVisible: false,
      zhimafenDisplay: false,
      demoDisplay: false,
      zhimafenImgUrl: '',
      qrVisible: false,
      confirmType: 2,
      zhimafenUrl: '', // 存在微信服务器上的图片id
      toastMsg: '',
      contact: '',
      showBrowserGuide: false,
      showToast: false,
      plan: {
        id: 0,
        name: '',
        price: 0,
        stName: ''
      },
      orderId: '',
      isWeixin: false,
      isPC: true,
      jssdkReady: false,
      isIOS: false,
      isApp: false,
      imgFile: {},
      cancelText: '稍后再付',
      confirmText: '继续支付',
      demoUrl: utils.getStaticUrl() + 'zhimafen/zhimademo.png',
      order: {
        status: 1,
        email: '',
        repeatEmail: '',
        skuId: 0,
        couponId: ''
      },
      coupons: [], // 显示的券
      allCoupons: [], // 所有券
      selectCoupon: {},
      checkedIdx: -1,
      paymentTooltipShow: true
    }
  },
  components: {
    Toast,
    Loading,
    Zhimafen,
    Confirm,
    WechatQR,
    Payment,
    ServiceAgreement,
    Coupon,
    PaymentWarning,
    PaymentInvitation
  },
  beforeRouteLeave(to, from, next) {
    if (this.order.status != 3) {
      this.cancelText = '稍后再付'
      this.confirmText = '继续支付'
      this.showConfirm(2)
        .then(res => {
          this.confirmVisible = false
          next(false)
        })
        .catch(e => {
          next()
        })
    }
  },
  methods: {
    paymentCheck(value) {
      this.checkedIdx = value
      if ([0, 1].indexOf(value) !== -1) {
        this.paymentTooltipShow = false
      }
    },
    couponStatusChange(status) {
      if (status) {
        // 不可用
        this.coupons = this.allCoupons.filter(coupon => {
          return (
            coupon.skus.filter(sku => sku.id == this.order.skuId).length == 0 ||
            new Date(coupon.userCoupon.expiredAt) < new Date()
          )
        })
      } else {
        // 可用优惠券
        this.coupons = this.allCoupons.filter(coupon => {
          return (
            coupon.skus.filter(sku => sku.id == this.order.skuId).length &&
            new Date(coupon.userCoupon.expiredAt) >= new Date()
          )
        })
      }
    },
    async getCoupons() {
      try {
        let res = await getCoupons({
          uuid: localStorage.getItem('uuid')
        })

        if (res.data.code == 0) {
          if (res.data.coupons.length && res.data.coupons[0].coupons) {
            this.allCoupons = res.data.coupons[0].coupons
            this.coupons = this.allCoupons.filter(coupon => {
              return (
                coupon.skus.filter(sku => sku.id == this.order.skuId).length &&
                new Date(coupon.userCoupon.expiredAt) >= new Date()
              )
            })
            this.selectCoupon = this.coupons.length
              ? this.coupons[0]
              : {
                  couponId: ''
                }
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    couponClose() {
      this.couponShow = false
    },
    couponConfirm(coupon) {
      this.selectCoupon = coupon
      this.couponShow = false
    },
    paymentMethodTab(method) {
      this.payMethod = method
    },
    async invitationCodeCheck() {
      try {
        if (this.invitation.code) {
          this.invitation.code = this.invitation.code.trim()

          if (this.invitation.code.length !== 6) {
            this.toastMsg = '邀请码不正确，请检查'
            this.showToast = true
            return
          } else {
            this.showLoading = true

            let res = await invitationCodeCheck({
              invitationCode: this.invitation.code,
              orderId: this.orderId
            })

            this.showLoading = false
            if (res.data.code != 0) {
              this.toastMsg = res.data.message
              this.showToast = true
              this.invitation.checked = false
            } else {
              this.invitation.checked = true
              this.invitation.discountAmount = res.data.discountAmount
            }
          }
        }
      } catch (error) {
        reportError(error)
      }
    },
    showConfirm(confirmType) {
      this.confirmType = confirmType
      this.confirmVisible = true
      return new Promise((resolve, reject) => {
        this.confirm = () => {
          resolve('success')
        }
        this.confirmCancel = () => {
          reject('fail')
        }
      })
    },
    zhimademo() {
      this.demoDisplay = true
    },
    zhimafenPreview() {
      this.zhimafenDisplay = true
    },
    handleInputChange(event) {
      const file = event.target.files[0]
      const imgMasSize = 1024 * 1024 * 3 // 3MB
      // 检查文件类型
      if (['jpeg', 'png', 'jpg'].indexOf(file.type.split('/')[1]) < 0) {
        this.toastMsg = '图片格式不支持'
        this.showToast = true
        this.$refs.image.value = null
        return
      }

      // 文件大小限制
      if (file.size > imgMasSize) {
        this.toastMsg = '图片太大啦'
        this.showToast = true
        this.$refs.image.value = null
        return
      }

      // 判断是否是ios
      if (!!window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        // iOS
        this.transformFileToFormData(file)
        return
      }

      // 图片压缩之旅
      this.transformFileToDataUrl(file)
    },
    // 将file转成dataUrl
    transformFileToDataUrl(file) {
      const imgCompassMaxSize = 1024 * 1024 // 超过 1MB 就压缩

      // 存储文件相关信息
      this.imgFile.type = file.type || 'image/jpeg' // 部分安卓出现获取不到type的情况
      this.imgFile.size = file.size
      this.imgFile.name = file.name
      this.imgFile.lastModifiedDate = file.lastModifiedDate

      // 封装好的函数
      const reader = new FileReader()

      // file转dataUrl是个异步函数，要将代码写在回调里
      reader.onload = e => {
        const result = e.target.result

        if (result.length < imgCompassMaxSize) {
          this.compress(result, this.processData, false) // 图片不压缩
        } else {
          this.compress(result, this.processData) // 图片压缩
        }
      }

      reader.readAsDataURL(file)
    },
    transformFileToFormData(file) {
      const formData = new FormData()
      // 自定义formData中的内容
      // type
      formData.append('type', file.type)
      // size
      formData.append('size', file.size || 'image/jpeg')
      // name
      formData.append('name', file.name)
      // lastModifiedDate
      formData.append('lastModifiedDate', file.lastModifiedDate)
      // append 文件
      formData.append('file', file)
      // 上传图片
      this.uploadImg(formData)
    },
    processData(dataURL) {
      // 这里使用二进制方式处理dataUrl
      const binaryString = window.atob(dataURL.split(',')[1])
      const arrayBuffer = new ArrayBuffer(binaryString.length)
      const intArray = new Uint8Array(arrayBuffer)
      const imgFile = this.imgFile

      for (let i = 0, j = binaryString.length; i < j; i++) {
        intArray[i] = binaryString.charCodeAt(i)
      }
      const data = [intArray]

      let blob

      try {
        blob = new Blob(data, { type: imgFile.type })
      } catch (error) {
        window.BlobBuilder =
          window.BlobBuilder ||
          window.WebKitBlobBuilder ||
          window.MozBlobBuilder ||
          window.MSBlobBuilder
        if (error.name === 'TypeError' && window.BlobBuilder) {
          const builder = new BlobBuilder()
          builder.append(arrayBuffer)
          blob = builder.getBlob(imgFile.type)
        } else {
          // Toast.error("版本过低，不支持上传图片", 2000, undefined, false);
          throw new Error('版本过低，不支持上传图片')
        }
      }

      // blob 转file
      const fileOfBlob = new File([blob], imgFile.name)
      const formData = new FormData()

      // type
      formData.append('type', imgFile.type)
      // size
      formData.append('size', fileOfBlob.size)
      // name
      formData.append('name', imgFile.name)
      // lastModifiedDate
      formData.append('lastModifiedDate', imgFile.lastModifiedDate)
      // append 文件
      formData.append('file', fileOfBlob)

      this.uploadImg(formData)
    },
    // 上传图片
    uploadImg(formData) {
      const xhr = new XMLHttpRequest()
      // 进度监听
      xhr.upload.addEventListener(
        'progress',
        e => {
          // console.log(e.loaded / e.total);
        },
        false
      )
      // 加载监听
      // xhr.addEventListener('load', ()=>{console.log("加载中");}, false);
      // 错误监听
      xhr.addEventListener(
        'error',
        () => {
          this.toastMsg = '上传失败！'
          this.showToast = true
          this.$refs.image.value = null
        },
        false
      )
      xhr.onreadystatechange = () => {
        this.showLoading = false
        this.$refs.image.value = null
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.responseText)
          if (xhr.status === 200) {
            this.zhimafenUrl = result.fileId
            this.zhimafenImgUrl =
              utils.getStaticUrl() + 'zhimafen/' + result.fileId
          } else {
            this.toastMsg = '上传失败！'
            this.showToast = true
          }
        }
      }
      this.showLoading = true
      xhr.open('POST', utils.getApiUrl() + 'file/upload', true)
      xhr.send(formData)
    },
    compress(dataURL, callback, shouldCompress = true) {
      const img = new window.Image()
      img.src = dataURL
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = img.width
        canvas.height = img.height

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

        let compressedDataUrl

        if (shouldCompress) {
          compressedDataUrl = canvas.toDataURL(this.imgFile.type, 0.2)
        } else {
          compressedDataUrl = canvas.toDataURL(this.imgFile.type, 1)
        }
        callback(compressedDataUrl)
      }
    },
    uploadImageHandle() {
      if (this.isWeixin) {
        let that = this
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
          success(res) {
            if (that.isIOS) {
              // IOS 无法显示
              wx.getLocalImgData({
                localId: res.localIds[0], // 图片的localID
                success: function (res) {
                  that.zhimafenImgUrl = res.localData // localData是图片的base64数据，可以用img标签显示
                }
              })
            } else {
              that.zhimafenImgUrl = res.localIds[0] // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
            }
            wx.uploadImage({
              localId: res.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
              isShowProgressTips: 1, // 默认为1，显示进度提示
              success(result) {
                console.log(result.serverId)
                that.zhimafenUrl = result.serverId // 返回图片的服务器端ID
              }
            })
          }
        })
      } else {
        this.$refs.image.click()
      }
    },
    delZhimafen() {
      this.zhimafenImgUrl = ''
      this.zhimafenUrl = ''
    },
    confirm() {},
    confirmCancel() {
      this.confirmVisible = false
    },
    async getOrderInfo(flag = false) {
      try {
        this.showBrowserGuide = false
        this.showLoading = true
        let res = await getOrderInfo({
          orderId: this.orderId
        })
        this.showLoading = false
        if (res.data.code == 0) {
          this.order = res.data.order
          this.plan.stName = res.data.order.stName
          this.plan.name = res.data.order.skuName
          this.plan.price = res.data.order.price
          this.plan.originPrice = res.data.order.originPrice

          // 如果有填过这些数据就直接显示出来，无需再填
          this.order.email = res.data.order.email || ''
          this.order.repeatEmail = res.data.order.email || ''
          this.contact = res.data.order.meta.contact || ''
          this.invitation.code = res.data.order.invitationCode || this.invitation.code
          this.zhimafenUrl = res.data.order.zhimafen || ''

          if (res.data.order.zhimafen) {
            this.zhimafenImgUrl =
              utils.getStaticUrl() + 'zhimafen/' + res.data.order.zhimafen
          }

          if (res.data.order.status == 2) {
            // 未付款
            if (flag) {
              this.toastMsg = '您的订单还未完成支付，请重新支付'
              this.showToast = true
            }
          } else if (res.data.order.status == 3) {
            // 付款成功
            window.location =
              '/carpool/purchase/pay-success?orderId=' + this.orderId
          }
        }
      } catch (error) {
        console.error(error)
        reportError(error)
      }
    },
    checkMail(email) {
      return /^[0-9a-zA-Z_.+-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/.test(
        email
      )
    },
    submitOrder: debounce(async function () {
      try {
        if (!this.order.email) {
          this.toastMsg = '邮箱不能为空'
          this.showToast = true
          return
        }
        this.order.email = this.order.email.replace(/\s+/g, '')
        this.order.repeatEmail = this.order.repeatEmail.replace(/\s+/g, '')
        if (!this.checkMail(this.order.email)) {
          this.toastMsg = '请输入正确的邮箱'
          this.showToast = true
          return
        }

        if (this.order.email !== this.order.repeatEmail) {
          this.toastMsg = '两次输入的邮箱不一致，请检查'
          this.showToast = true
          return
        }

        if (this.plan.stName.indexOf('芝麻') !== -1) {
          if (!this.zhimafenUrl) {
            this.toastMsg = '请上传芝麻分截图'
            this.showToast = true
            return
          }
        }
        if (!this.isWeixin) {
          if (!this.contact) {
            this.toastMsg = '联系方式不能为空'
            this.showToast = true
            return
          }
          if (!/^1[3456789]\d{9}$/.test(this.contact)) {
            this.toastMsg = '手机号填写错误'
            this.showToast = true
            return
          }
        }

        if ([0, 1].indexOf(this.checkedIdx) == -1) {
          this.paymentTooltipShow = true
          return
        }

        this.showLoading = true
        let purchaseParams = {
          email: this.order.email,
          contact: this.contact,
          orderId: this.orderId,
          isWeixin: this.isWeixin,
          payMethod: this.payMethod,
          zhimafenUrl: this.zhimafenUrl
        }

        if (this.checkedIdx == 1) {
          purchaseParams['isNewAccount'] = true
        }

        if (this.selectCoupon.couponId) {
          purchaseParams['couponId'] = this.selectCoupon.couponId
        }
        if (this.isPC) purchaseParams['qr'] = true
        if (this.invitation.code)
          purchaseParams['invitationCode'] = this.invitation.code
        let res = await purchase(purchaseParams)
        this.showLoading = false
        if (res.data.code == 0) {
          if (this.payMethod == 'alipay') {
            if (this.isWeixin) {
              // 在微信环境中打开，指引去浏览器打开
              const newUrl =
                this.$route.path + `?orderid=${this.orderId}&payMethod=alipay`
              window.history.replaceState('', '', newUrl)
              this.showBrowserGuide = true
            } else {
              const div = document.createElement('formdiv')
              div.innerHTML = res.data.data
              document.body.appendChild(div)
              document.forms[0].setAttribute(
                'target',
                this.isPC ? '_blank' : '_self'
              )
              document.forms[0].submit()
              div.remove()
              setTimeout(() => {
                this.cancelText = '未完成'
                this.confirmText = '完成'
                this.showConfirm(1)
                  .then(res => {
                    this.confirmVisible = false
                    this.getOrderInfo(true)
                  })
                  .catch(e => {
                    this.confirmVisible = false
                  })
              }, 5000)
            }
          } else {
            if (this.isWeixin) {
              if (typeof WeixinJSBridge == 'undefined') {
                if (document.addEventListener) {
                  document.addEventListener(
                    'WeixinJSBridgeReady',
                    this.jsPay(res.data.data),
                    false
                  )
                } else if (document.attachEvent) {
                  document.attachEvent(
                    'WeixinJSBridgeReady',
                    this.jsPay(res.data.data)
                  )
                  document.attachEvent(
                    'onWeixinJSBridgeReady',
                    this.jsPay(res.data.data)
                  )
                }
              } else {
                this.jsPay(res.data.data)
              }
            } else if (this.isPC) {
              let routeUrl = this.$router.resolve({
                path: '/carpool/purchase/pay-pc',
                query: {
                  orderId: this.orderId,
                  qrUrl: res.data.data.codeUrl
                }
              })
              setTimeout(() => {
                this.cancelText = '未完成'
                this.confirmText = '完成'
                this.showConfirm(1)
                  .then(res => {
                    this.confirmVisible = false
                    this.getOrderInfo(true)
                  })
                  .catch(e => {
                    this.confirmVisible = false
                  })
              }, 1500)
              window.open(routeUrl.href, '_blank')
            } else {
              window.location.href = res.data.data.url
              setTimeout(() => {
                this.showConfirm(1)
                  .then(res => {
                    this.confirmVisible = false
                    this.getOrderInfo(true)
                  })
                  .catch(e => {
                    this.confirmVisible = false
                  })
              }, 1500)
            }
          }
        } else if (res.data.code == 2) {
          // 订单已支付
          this.$router.push(
            '/carpool/purchase/pay-success?orderId=' + this.orderId
          )
        } else {
          this.toastMsg = res.data.message
          this.showToast = true
        }
      } catch (error) {
        reportError(error)
      }
    }, 200),
    jsPay(data) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: 'wx1d62934604321a9c', //公众号名称，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式
          paySign: data.paySign //微信签名
        },
        res => {
          this.getOrderInfo()
          // alert(res.err_msg)
          // if (res.err_msg == "get_brand_wcpay_request:ok" ){
          //   // 使用以上方式判断前端返回,微信团队郑重提示：
          //   //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          //   this.getOrderInfo(true)
          // } else {
          //   this.toastMsg = '您的订单还未完成支付，请重新支付'
          //   this.showToast = true
          // }
        }
      )
    }
  },
  async created() {
    try {
      this.invitation.code = localStorage.getItem('ic') || ''

      let ua = navigator.userAgent

      this.isApp = !!ua.match(/citicbankmobile/i)

      this.isIOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //iOS
      ua = ua.toLowerCase()
      this.isWeixin = ua.indexOf('micromessenger') !== -1
      let phoneAgents = [
        'android',
        'iphone',
        'symbianos',
        'windows phone',
        'ipad',
        'ipod'
      ]
      for (let agent of phoneAgents) {
        if (ua.indexOf(agent) !== -1) {
          this.isPC = false
          break
        }
      }

      if (this.isPC) this.isWeixin = false // 如果是PC微信端浏览器打开，就当作PC打开

      if (this.$route.query.orderid) {
        this.orderId = this.$route.query.orderid

        if (this.$route.query.payMethod == 'alipay') {
          this.payMethod = 'alipay'

          if (!this.isWeixin) {
            let purchaseParams = {
              orderId: this.orderId,
              isWeixin: false,
              payMethod: 'alipay'
            }
            this.showLoading = true
            let res = await purchase(purchaseParams)
            this.showLoading = false
            if (res.data.code == 0) {
              const div = document.createElement('formdiv')
              div.innerHTML = res.data.data
              document.body.appendChild(div)
              document.forms[0].setAttribute(
                'target',
                this.isPC ? '_blank' : '_self'
              )
              document.forms[0].submit()
              div.remove()
              setTimeout(() => {
                this.cancelText = '未完成'
                this.confirmText = '完成'
                this.showConfirm(1)
                  .then(res => {
                    this.confirmVisible = false
                    this.getOrderInfo(true)
                  })
                  .catch(e => {
                    this.confirmVisible = false
                  })
              }, 5000)
            }
          }
        }

        await this.getOrderInfo()
        this.getCoupons()
        if (this.plan.stName.indexOf('芝麻车') !== -1 && this.isWeixin) {
          // 芝麻车微信端需要进行jssdk初始化，，其中，IOS放在页面加载时就进行初始化，安卓每个页面都需要初始化
          if (!this.jssdkReady && !this.isIOS) {
            let url = encodeURIComponent(window.location.href.split('#')[0])
            let res = await getJsapiSign({
              url
            })
            if (res.data.code == 0) {
              wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: 'wxadf4a59cd613d104', // 必填，公众号的唯一标识
                timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                signature: res.data.signature, // 必填，签名
                jsApiList: ['chooseImage', 'uploadImage'] // 必填，需要使用的JS接口列表
              })
              wx.ready(() => {
                this.jssdkReady = true
              })
              wx.error(function (res) {
                reportError(res)
              })
            }
          }
        }
      } else {
        this.$router.push('/home')
      }
    } catch (error) {
      reportError(error)
    }
  }
}
</script>

<style lang="less" scoped>
.shangche {
  width: 7.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100% !important;
  padding-bottom: 1.38rem;
  background: #f8f8f8;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .invitation-mask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: 0 auto;
    background: rgba(89, 89, 89, 0.9);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;

    .invitation-box {
      font-size: 0.28rem;
      display: flex;
      padding: 0.3rem 0.2rem;
      flex-direction: column;
      width: 6.04rem;
      background: #ffffff;
      border-radius: 0.16rem;
      .question {
        font-weight: bold;
        color: #333333;
      }
      .answer {
        margin-top: 0.12rem;
        margin-bottom: 0.24rem;
      }
      .tips {
        color: #69d9b6;
        margin-top: 0.06rem;
        margin-bottom: 0.24rem;
      }
      .footer-btn {
        margin-left: -0.2rem;
        margin-right: -0.2rem;
        border-top: 0.02rem solid #f3f3f3;

        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.2rem;
        color: #69d9b6;
        cursor: pointer;
      }
    }
  }

  .coupon-discount {
    font-size: 0.28rem;
    color: #c5c6c5;
    margin-right: 0.06rem;
    &.have {
      color: #ff4a4a;
    }
  }
  .car-form {
    width: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .product {
      margin-bottom: 0.12rem;
      background: #ffffff;
      width: 7.02rem;
      padding: 0.12rem 0.24rem;
      height: 1.8rem;
      display: flex;
      align-items: center;
      img {
        width: 1.4rem;
        height: 1.4rem;
      }
      .sku {
        margin-left: 0.18rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        font-size: 0.28rem;
        height: 1.4rem;
      }
    }
    .input-item {
      height: 0.9rem;
      width: 7.02rem;
      padding: 0.06rem 0.24rem;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .coupon-info {
        cursor: pointer;
        display: flex;
        align-items: center;
        .coupon-more {
          margin-top: 0.04rem;
          width: 0.18rem;
          height: 0.18rem;
          border-top: 0.02rem solid #c5c6c5;
          border-right: 0.02rem solid #c5c6c5;
          transform: rotate(45deg);
        }
      }
      .title {
        font-size: 0.28rem;
      }
      .title-star {
        font-size: 0.28rem;
        &::after {
          content: '*';
          color: red;
        }
      }
      input {
        width: 65%;
        border: none;
        height: 0.4rem;
        padding: 0.24rem 0;
        font-size: 0.28rem;
        outline: none;
        text-align: right;
        &::placeholder {
          font-size: 0.28rem;
          color: #c5c6c5;
        }
        &:focus {
          border: none;
        }
        &:active {
          border: none;
        }
        &:-moz-placeholder {
          text-align: right;
        }
        &::-moz-placeholder {
          text-align: right;
        }
        &::-webkit-input-placeholder {
          text-align: right;
        }
        &:-ms-input-placeholder {
          text-align: right;
        }
      }
      border-bottom: 0.02rem solid #f6f7f8;
      &:last-child {
        border-bottom: 0;
      }
      .screenshot-demo {
        font-size: 0.28rem;
        color: #54cea8;
        cursor: pointer;
      }
    }
    .zhimafen-upload {
      background: #ffffff;
      width: 7.02rem;
      padding: 0 0.24rem;
      padding-bottom: 0.24rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.02rem;
      .upload-default {
        cursor: pointer;
        border-radius: 0.04rem;
        width: 1.6rem;
        height: 1.6rem;
        background: #f5f6f8;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.7rem;
        font-weight: bolder;
        color: gray;
      }
      .zhimafen-wrap {
        position: relative;
        background: #ffffff;
        img {
          cursor: pointer;
          border-radius: 0.04rem;
          width: 1.6rem;
          height: 1.6rem;
        }
        .zhimafen-close {
          cursor: pointer;
          top: 0.12rem;
          right: 0.12rem;
          position: absolute;
          width: 0.32rem;
          height: 0.32rem;
          background: rgb(72 73 76);
          border-radius: 50%;
          &::before,
          &::after {
            content: '';
            position: absolute; /*方便进行定位*/
            height: 0.16rem;
            width: 0.02rem;
            border-radius: 0.04rem;
            top: 0.08rem;
            right: 0.15rem; /*设置top和right使图像在20*20框中居中*/
            background: white;
          }
          &::before {
            transform: rotate(45deg); /*进行旋转*/
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
      .upload-desc {
        height: 1.5rem;
        font-size: 0.22rem;
        color: #c0c0c0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 0.2rem;
      }
    }
  }

  .footer {
    background: #ffffff;
    height: 1.38rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 7.5rem;
    .pay {
      width: 7.5rem;
      height: 1.38rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .pay-amount-agreement {
        display: flex;
        flex-direction: column;
        margin-left: 0.24rem;
        .pay-amount {
          font-size: 0.28rem;
          .label {
            color: #050608;
          }
          .currency {
            font-size: 0.2rem;
            color: #ff4a4a;
          }
          .amount {
            font-size: 0.4rem;
            color: #ff4a4a;
          }
        }
        .agreement {
          margin-top: 0.12rem;
          color: #c5c6c5;
          font-size: 0.24rem;
        }
      }

      .pay-button {
        margin-right: 0.24rem;
        width: 2.2rem;
        border: none;
        border-radius: 0.4rem;
        font-size: 0.32rem;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
        height: 0.8rem;
        &.ex {
          background: linear-gradient(90deg, #e67649 0%, #e5884b 100%);
        }
        &:disabled {
          background: #f6f7f8;
          color: #999999;
        }
        ::after {
          border: none;
        }
      }
    }
  }
  .confirm-body {
    height: 2.2rem;
    width: 100%;
    font-size: 0.32rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: #939393;
    .dialog-body-row {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .rights {
        width: 3.8rem;
        height: 0.52rem;
        display: flex;
        justify-content: space-between;
        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.56rem;
          height: 0.52rem;
          font-size: 0.24rem;
          background: #f8f8f8;
          img {
            width: 0.28rem;
            height: 0.28rem;
            margin-right: 0.04rem;
          }
        }
      }
    }
  }
  .cs {
    position: fixed;
    bottom: 3rem;
    right: 0.24rem;
    cursor: pointer;
    .cs-wrap {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      box-shadow: 0px 0px 0.04rem 0.04rem rgba(0, 0, 0, 0.07);
    }
  }
}
</style>
