<style lang="less" scoped>
.agreement-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  background: rgba(32, 32, 32, 0.81);
  .agreement-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .agreement {
      font-size: 0.26rem;
      width: 6.44rem;
      background: white;
      border-radius: 0.16rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 10rem;
      .agreement-title {
        display: flex;

        align-items: center;
        justify-content: center;

        height: 1.22rem;
        font-size: 0.36rem;
        font-weight: 500;
        color: #2c2c2c;
        min-height: 1.22rem;
      }
      .agreement-body {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #333333;
        font-size: 0.24rem;
        text-align: left;
        padding: 0.38rem 0.24rem;
        padding-top: 0;
        line-height: 0.44rem;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        .bold {
          font-weight: bold;
        }
        .paragraph {
          margin-bottom: 0.24rem;
        }
      }

      .agreement-footer {
        width: 100%;
        border-top: 0.02rem solid #eeeeee;
        height: 1.1rem;
        min-height: 1.1rem;
        display: flex;
        .agreement-confirm {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          font-size: 0.36rem;
          color: #54cea4;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<template>
  <div class="agreement-mask" v-if="visible">
    <div class="agreement-container">
      <div class="agreement">
        <div class="agreement-title">YouthSwitch会员服务协议</div>
        <div class="agreement-body">
          <div class="paragraph">
            <div class="bold">特别提示</div>
            <div class="content">
              厦门亦游人科技有限公司
              (以下简称“本平台”)在此特别提醒您，在您开通成为YouthSwitch会员之前，请认真阅读《YouthSwitch会员服务协议》(以下简称“本协议”)，确保您充分理解各条款，包括免除本平台责任的条款及限制会员权利的条款。请您审慎阅读并选择接受或不接受本协议，未成年人则应在法定监护人陪同下阅读。除非您已阅读并接受本协议所有条款，否则您无权使用“YouthSwitch会员”服务。您对“YouthSwitch会员”服务的购买和使用行为即视为您已阅读并同意接受本协议的约束。本协议内容中以黑体、加粗等方式显著标识的条款请您着重阅读。<br />
              本协议约定本平台与您之间关于“YouthSwitch会员”服务的各项事项。“会员”是指注册、登录、使用本协议项下本平台提供的各种服务的个人
              (以下称“会员”或“您”)。YouthSwitch会员服务是有偿的增值服务，您必须完全同意本协议，并完成付费，才能享受相关服务。
              您开通会员服务成为会员用户，即表示您同意接受本协议里各种规范、规则的各项约束，并将遵守。如果您不接受本协议，则不能享受YouthSwitch会员服务。<br />
              本协议可由本平台随时更新，更新后的协议一旦公布即代替原来的协议内容，您可在本平台内查阅最新版本。
            </div>
          </div>
          <div class="paragraph">
            <div class="bold">一、总则</div>
            1、YouthSwitch是由厦门亦游人科技有限公司研发并运营的H5页面及小程序产品。<br />
            2、YouthSwitch用户是指完成全部注册流程，愿意接受《YouthSwitch会员服务协议》并在YouthSwitch相关使用规范的规定下使用YouthSwitch付费服务的注册用户。<br />
            3、YouthSwitch会员服务是YouthSwitch为用户提供的有偿增值服务，用户向YouthSwitch支付相关费用后，方可享受专门为会员提供的各项服务。<br />
            4、YouthSwitch会员必须遵守《YouthSwitch会员服务协议》的协议条款。<br />
          </div>

          <div class="paragraph">
            <div class="bold">二、服务费用</div>
            1、YouthSwitch会员增值服务订阅期有不同选择，具体订阅期和资费标准以购买页面公布的为准，用户可直接选择第三方支付方式进行购买。本平台将根据不同的会员类型、购买方式和数量，向用户提供不同幅度的优惠，具体优惠政策以相关页面的公告、说明或会员支付页面显示的内容为准。<br />
            2、您可以通过登录YouthSwitch订单中心查询您的账号信息详情，包括已开通的会员类型、服务内容、服务期限等。<br />
            3、本平台有权基于自身业务发展需要调整会员服务费用标准，调整后的费用标准将在相关支付页面显示，用户按调整后的服务费用标准支付后方可享有或延续会员。已按原会员服务费用标准购买会员服务的用户，其会员在已取得的会员期限内不受影响。<br />
          </div>
          <div class="paragraph">
            <div class="bold">三、服务内容</div>
            1、本平台将为会员提供多种专属特权服务，具体内容以产品页面展示内容为准。<br />
            2、本平台有权基于自身业务发展需要调整会员服务内容，您应及时关注和了解会员特权内容及政策的变化，并理解和同意本平台的调整。本平台对会员特权进行调整之前，您可能已享有或正在享有一定的服务内容或权益，您理解和接受本平台对该特权进行调整可能会对您已享有或正在享有的特权造成影响，并同意按照调整后的会员服务内容进行使用，而不要求本平台承担任何责任。您可通过本平台的客服问询最新的会员服务内容。
          </div>
          <div class="paragraph">
            <div class="bold">四、服务的开通和终止</div>

            1、YouthSwitch购买任天堂官方的家庭会员，其中年度会员的时长是<span
              class="bold"
            >
              365天 </span
            >，为了保证每个人上车就能享受到会员服务，YouthSwitch平台会统一提前1-2天开通好会员，所以您的年度会员时长会在
            363-365 天之间，如果给您分配的家庭会员剩余时长小于 363
            天（极小部分用户），系统会自动将缺少的天数折算成退款余额直接返回您的付款账户，敬请留意。<br />
            2、用户通过YouthSwitch平台提供的付费途径完成会员费用的支付后，即取得相应YouthSwitch会员。<br />
            <span class="bold"
              >3、提交申请并开通会员时，您需核对并提交合法有效的任天堂账号、开通服务类型与时长等信息。如因您个人原因造成充错帐号、开通错服务或时长，本平台不予退还已收取的费用。</span
            ><br />
            <span class="bold"
              >4、当您的提交的任天堂账号信息发生变化时，账号信息变化包含但不限于：您的任天堂账号改邮箱、主动退出家庭、被任天堂公司封禁、被任天堂公司或者本平台列为风险账号等，导致的会员服务终止，本平台不予退还已收取的费用。</span
            ><br />
            5、会员有效期自YouthSwitch会员服务开通之时起算，具体期限可以登录YouthSwitch“查订单”页面或者小程序-会员拼车-我的车票等页面查看。<br />
            6、会员有效期届满后，本平台将终止向您提供会员增值服务，您可以通过续交会员服务费用延续会员。<br />
            7、会员在会员有效期内续费，会员有效期将在原有效期基础上相应顺延。<br />
          </div>
          <div class="paragraph">
            <div class="bold">五、会员的权利和义务</div>
            1、YouthSwitch会员平等享有会员的权利，同时应遵守本平台的各项规范、规则，包括但不限于本协议。<br />
            2、因违反本协议第五条第1款所述的相关协议或平台规则导致会员服务被暂停使用而导致的损失，由您自行承担。<br />
            3、会员有效期内，会员可以享受相应的付费服务。<br />
            4、在会员有效期内，您主动终止或取消会员的，本平台不负责退还与剩余会员有效期对应的服务费用。<br />
            5、会员自行承担在YouthSwitch中传送、发布信息及使用YouthSwitch免费服务或收费服务的法律责任，会员使用YouthSwitch服务应遵守各项法律法规、规章、规范性文件(以下简称“法律法规”)以及平台协议及规则。<br />
            6、会员开通后，您应谨慎合理的保存、使用账号，不得将账号借予他人使用。否则，您应承担由此产生的全部责任。同时，YouthSwitch在该种情况下有权作出独立判断，可采取暂停或关闭用户会员等措施。<br />
            7、会员服务仅限于申请账号个人使用，不得用于商业等用途;会员服务期内不能在YouthSwitch帐号之间转移，不得赠与借用、转让或售卖。<br />
            8、会员不得以盗窃、利用系统漏洞等非法途径以及在未获YouthSwitch授权的非法销售YouthSwitch会员的网站上获取或购买会员服务，否则因此导致的损失由会员自行承担。
          </div>
          <div class="paragraph">
            <div class="bold">六、本平台的权利和义务</div>
            <span class="bold"
              >1、本平台应保证会员服务的正常使用，出现技术事故时应尽快排除，但对于会员因此产生的任何损失，本平台不承担责任。</span
            ><br />
            <span class="bold"
              >2、因不可抗力造成会员服务提供的中断或其它缺陷，本平台不承担任何责任。其中不可抗力因素，包括但不限于天灾、战争、暴乱、司法、政府命令、支付服务、网络接入服务、电信部门的通讯线路故障、通讯技术问题、电脑故障、系统不稳定性、任意第三方的侵权行为、任天堂公司政策、任天堂会员规则变动、任天堂账号条款变动、任天堂账号风控变动、六大运营商的法规和政策等其它法律要求以及所有无法控制的事件。</span
            ><br />
            3、会员应自行妥善保管任天堂账号密码，因会员自身原因导致账号密码泄露而造成的损失，由会员自行承担。但为避免账号被盗用等情形给会员造成损失，YouthSwitch会在您举报后采取合理措施避免第三方对会员账号实施操作行为，而不论该第三方基于何种目的、获取方式是否善意。但YouthSwitch采取措施的前提需会员提供相应的材料以证明其为账号的合法使用者，您应予以配合。<br />
            4、会员在使用YouthSwitch服务时，故意作出有损本平台、其他YouthSwitch用户合法权益的行为或违反《YouthSwitch会员服务协议》的相关约定，本平台有权取消会员或中断一项或多项会员服务而无须给予任何补偿，与此同时，本平台保留追究会员法律责任的权利。<br />
            5、会员在使用YouthSwitch服务时，存在违反法律法规的行为本平台有权取消会员而无须给予任何补偿，且会员须自行承担全部责任。
          </div>
          <div class="paragraph">
            <div class="bold">七、其他</div>
            1、本平台不时发布的关于会员服务的规范、规则等，是本协议不可分割的一部分。<br />
            2、本协议的解释，效力及纠纷的解决，适用中华人民共和国法律。<span
              class="bold"
              >本协议签订地为福建省厦门市集美区</span
            >。若会员和本平台之间发生任何纠纷或争议，首先应友好协商解决，<span
              class="bold"
              >协商不成的，会员同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖</span
            >。本协议条款因与中华人民共和国现行法律相抵触而导致部分条款无效的，不影响其他条款的效力。<br />
            3、本平台不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响本平台在将来行使该权利。<br />
            4、如果您对本条款内容有任何疑问，请发送邮件至我们的客服邮箱:
            bd@youthgg.com<br />
          </div>
          <div class="bold" style="font-size: 0.36rem">
            厦门亦游人科技有限公司
          </div>
        </div>

        <div class="agreement-footer">
          <div class="agreement-confirm" style="width: 100%" @click="close">
            阅读完了
          </div>
        </div>
      </div>

      <div class="close-area"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agreement',
  data () {
    return {}
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
