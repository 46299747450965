<template>
  <div class="coupons-mask" v-if="display">
    <div class="containner">
      <div class="blank"></div>
      <div class="coupons-wrap">
        <div class="coupons-header">
          <div class="label">优惠券</div>
          <div @click="close" class="close-icon-wrap">
            <div class="close-icon"></div>
          </div>
        </div>
        <div class="coupons-available-tabs">
          <div
            class="coupons-tab"
            @click="couponStatusChange(idx)"
            :class="{ active: tabIdx == idx }"
            v-for="(tab, idx) in ['可用优惠券', '不可用优惠券']"
          >
            {{ tab }}
          </div>
        </div>
        <div class="coupons-list" v-if="coupons.length">
          <div
            class="coupon-box"
            @click="couponTap(index)"
            v-for="(coupon, index) in coupons"
          >
            <div class="coupon-head" :class="{ unavailable: tabIdx == 1 }">
              <div class="coupon-amount-info">
                <div class="coupon-amount">{{ coupon.discount }}</div>
                <div class="coupon-currency">折</div>
              </div>
              <div class="coupon-condition">无门槛</div>
            </div>
            <div class="coupon-body" :class="{ unavailable: tabIdx == 1 }">
              <div class="coupon-info">
                <div class="coupon-name">{{ coupon.name }}</div>
                <div class="coupon-expiration">
                  {{
                    getYMD(coupon.createdAt) + ' - ' + getYMD(coupon.expiredAt)
                  }}
                </div>
              </div>
              <div class="coupon-check" v-if="tabIdx == 0">
                <svg
                  v-if="index == couponActIdx"
                  t="1679918602414"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2759"
                  width="0.40rem"
                  height="0.40rem"
                >
                  <path
                    d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m-74.965333 550.4L346.453333 545.152a42.666667 42.666667 0 1 0-60.330666 60.330667l120.704 120.704a42.666667 42.666667 0 0 0 60.330666 0l301.653334-301.696a42.666667 42.666667 0 1 0-60.288-60.330667l-271.530667 271.488z"
                    fill="#52C41A"
                    p-id="2760"
                  ></path>
                </svg>
                <div v-else class="check"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="coupons-list" v-else style="justify-content: center">
          <span style="font-size: 0.28rem; color: #999999">暂无优惠券</span>
        </div>
        <div class="confirm-btn-wrap">
          <div class="confirm-btn" @click="confirm">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { utils } from '@/utils'

export default {
  props: {
    display: {
      type: Boolean,
      default: false
    },
    coupons: {
      type: Array
    }
  },
  data () {
    return {
      couponActIdx: 0,
      tabIdx: 0
    }
  },
  methods: {
    couponStatusChange (idx) {
      this.tabIdx = idx
      this.$emit('couponStatusChange', idx)
    },
    getYMD (date) {
      return utils.getYMD(date)
    },
    close () {
      this.$emit('close')
    },
    couponTap (idx) {
      if (this.tabIdx == 0) {
        console.log(this.couponActIdx, idx)
        if (this.couponActIdx == idx) {
          this.couponActIdx = -1
        } else {
          this.couponActIdx = idx
        }
      }
    },
    confirm () {
      this.$emit(
        'confirm',
        this.coupons.length ? (this.couponActIdx == -1 ? {} : this.coupons[this.couponActIdx]) : {}
      )
    }
  }
}
</script>
<style scoped lang="less">
.coupons-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.65);
  .containner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    .blank {
      flex: 1;
      width: 7.5rem;
    }
    .coupons-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 7.5rem;
      max-height: 9.6rem;
      min-height: 9.6rem;
      background: #ffffff;
      padding-bottom: 0.24rem;
      border-radius: 0.2rem 0.2rem 0px 0px;
      .coupons-header {
        display: flex;
        position: relative;
        width: 100%;
        align-items: center;
        height: 1rem;

        .label {
          margin-left: 0.24rem;
          font-size: 0.28rem;
          font-weight: 500;
          color: #333333;
        }
        .close-icon-wrap {
          position: absolute;
          cursor: pointer;
          top: 0.2rem;
          right: 0.24rem;
          width: 0.8rem;
          height: 0.8rem;
          .close-icon {
            width: 0.5rem;
            height: 0.5rem;
            &::before,
            &::after {
              content: '';
              position: absolute; /*方便进行定位*/
              height: 0.3rem;
              width: 0.02rem;
              border-radius: 0.04rem;
              top: 0.1rem;
              right: 0.21rem; /*设置top和right使图像在20*20框中居中*/
              background: rgba(0, 0, 0, 0.5);
            }
            &::before {
              transform: rotate(45deg); /*进行旋转*/
            }
            &::after {
              transform: rotate(-45deg);
            }
          }
        }
      }
      .coupons-available-tabs {
        display: flex;
        justify-content: space-between;
        font-size: 0.28rem;
        width: 4.4rem;

        .coupons-tab {
          font-weight: 400;
          color: #999999;
          cursor: pointer;
          &.active {
            font-weight: 500;
            color: #333333;
          }
        }
      }
      .coupons-list {
        margin-top: 0.36rem;
        flex: 1;
        border-top: 0.02rem solid #f5f5f5;
        display: flex;
        width: 7.5rem;
        align-items: center;
        overflow-x: hidden;
        overflow-y: auto;
        background: white;
        flex-direction: column;
        .coupon-box {
          margin-top: 0.12rem;
          &:first-child {
            margin-top: 0.28rem;
          }
          cursor: pointer;
          flex-shrink: 0;
          white-space: nowrap;
          width: 7.02rem;
          height: 1.84rem;
          position: relative;
          display: flex;

          .coupon-head {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            width: 2.02rem;
            height: 1.84rem;
            background: #55cfa5;
            border-radius: 0.04rem 0px 0px 0.04rem;
            &.unavailable {
              background: #cccccc;
            }
            .coupon-amount-info {
              font-weight: 500;
              font-size: 0.72rem;
              color: #ffffff;
              align-items: center;
              display: flex;
              .coupon-currency {
                font-size: 0.24rem;
                margin-top: 0.24rem;
                margin-left: 0.05rem;
              }
            }
            .coupon-condition {
              font-size: 0.24rem;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .coupon-body {
            &.unavailable {
              background: #f2f2f2;
            }
            display: flex;
            height: 1.84rem;
            width: 5rem;
            background: #e0fdf3;
            border-radius: 0rem 0.04rem 0.04rem 0;
            .coupon-info {
              width: 3.92rem;
              height: 100%;
              display: flex;
              flex-direction: column;
              font-weight: 500;
              justify-content: center;
              font-size: 0.28rem;
              color: #333333;
              margin-left: 0.36rem;
              .coupon-expiration {
                color: #666666;
                margin-top: 0.24rem;
              }
            }
            .coupon-check {
              width: 1.08rem;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .check {
                width: 0.28rem;
                height: 0.28rem;
                border-radius: 50%;
                border: 0.03rem solid #969799;
              }
            }
          }
        }
      }
      .confirm-btn {
        cursor: pointer;
        margin-top: 0.52rem;
        width: 7.02rem;
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        color: #ffffff;
        border-radius: 0.4rem;
        background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);
        &.advance {
          background: linear-gradient(90deg, #f7dda2 0%, #fac7b3 100%);
          color: #a96c3d;
        }
      }
    }
  }
}
</style>
