<template>
  <div class="broadcast" :style="styleCustom">
    <div class="broadcast-content-wrap">

      <div class="con-text">
        您所填写的账号是新号吗？
      </div>

      <div class="conditions">

        <div class="radio" @click="check(0)">
          <svg v-if="checkedIdx == 0" t="1679918602414" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="2759" width="0.40rem" height="0.40rem">
            <path
              d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m-74.965333 550.4L346.453333 545.152a42.666667 42.666667 0 1 0-60.330666 60.330667l120.704 120.704a42.666667 42.666667 0 0 0 60.330666 0l301.653334-301.696a42.666667 42.666667 0 1 0-60.288-60.330667l-271.530667 271.488z"
              fill="#52C41A" p-id="2760"></path>
          </svg>
          <div v-else class="check-wrapper">
            <div class="check"></div>
          </div>

          <div class="label">不是新号</div>
        </div>

        <div class="radio" style="margin-left: 0.76rem;" @click="check(1)">
          <svg v-if="checkedIdx == 1" t="1679918602414" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="2759" width="0.40rem" height="0.40rem">
            <path
              d="M512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667s-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333z m-74.965333 550.4L346.453333 545.152a42.666667 42.666667 0 1 0-60.330666 60.330667l120.704 120.704a42.666667 42.666667 0 0 0 60.330666 0l301.653334-301.696a42.666667 42.666667 0 1 0-60.288-60.330667l-271.530667 271.488z"
              fill="#52C41A" p-id="2760"></path>
          </svg>
          <div v-else class="check-wrapper">
            <div class="check"></div>
          </div>
          <div class="label">是新号（从未加入过家庭）</div>
        </div>
      </div>

      <div class="broadcast-content" v-if="checkedIdx == 0">
        <div class="warning-title">
          不是新号的话，账号需满足以下条件：
        </div>
        <div class="warning-tips">
          1. 近1年内 <span style="color:red;">没当过家长邀请过人</span><br />
          2. 2023-11-01后 <span style="color:red;">没新加入过其他家庭(之前加入的没问题)</span><br />
          <span style="color:red;">不满足以上条件的账号，请勿提交！</span><br />

        </div>
        <span class="needtoknow">上车须知</span>
        <div class="tips">
          <div class="tip-icon"></div>
          <div class="tip-text">
            如您不确定账号是否符合条件，可登录邮箱查询邮件最新加入时间（作为成员）/寄出邀请时间（作为家长）。
          </div>
        </div>
        <div class="tips">
          <div class="tip-icon"></div>
          <div class="tip-text">
            因任天堂新规，<span style="color:red;">极少</span>不符合以上条件的成员加入我们家庭内，可能会使家庭受限无法再邀请其他人。届时系统将自动检测那名成员并踢出（此成员不支持退款）。
          </div>
        </div>
        <!-- <p class="broadcast-text" v-html="content"></p> -->
      </div>
    </div>
    <div class="tooltip-wrapper" v-if="tooltipShow">
      <div class="tooltip" content="请选择账号信息"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'broadcast',
  props: {
    styleCustom: {
      type: Object
    },
    checkedIdx: {
      type: Number,
      default: -1
    },
    tooltipShow: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    check(checkedIdx) {
      this.$emit('check', checkedIdx)
    }
  },
  data() {
    return {
      content: `
      
        1. 近 1 年内 <span style="color:red;">没当过家长(以退出家庭时间为准)</span></br>
        2. 2023-11-01 后 <span style="color:red;">没新加入过其他家庭</span> </br></br>

        不满足以上条件的账号，请勿下单！否则加入家庭后也会被检测到并移出家庭（<span style="color:red;">不支持退款</span>）
      `
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.broadcast {
  width: 7.5rem;
  display: flex;
  align-items: center;
  background: #fff1c0;
  flex-direction: column;

  padding-bottom: 0.24rem;

  .broadcast-content-wrap {
    width: 7.02rem;
    overflow: hidden;
    color: #3d3d3d;
    padding: 0.24rem 0.24rem 0rem 0.24rem;

    .broadcast-content {
      display: flex;
      flex-direction: column;

      .broadcast-text {

        font-size: 0.24rem;
      }

      .warning-title {
        margin-top: 0.34rem;
        color: #333;
        font-family: "PingFang SC";
        font-size: 0.24rem;
        font-style: normal;
        font-weight: bold;
        line-height: 0.4rem;
        /* 166.667% */
      }

      .warning-tips {
        color: #333;
        margin-top: 0.12rem;
        font-family: "PingFang SC";
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.48rem;
        /* 200% */
      }

      .needtoknow {
        margin-top: 0.26rem;
        color: #333;
        font-family: "PingFang SC";
        font-size: 0.28rem;
        font-style: normal;
        font-weight: bold;
        line-height: 0.48rem;
        /* 171.429% */
        z-index: 9999;
        position: relative;

        &::after {
          content: '';
          background: #23D49D;
          height: 0.08rem;
          width: 1.1rem;
          position: absolute;
          bottom: 0.08rem;
          left: 0;
          z-index: -998;
        }
      }

      .tips {
        display: flex;
        color: #333;
        font-family: "PingFang SC";
        font-size: 0.24rem;
        font-style: normal;
        align-items: flex-start;
        font-weight: 400;
        line-height: 0.48rem;
        /* 200% */

        .tip-icon {
          width: 0;
          height: 0;
          border-left: 0.18rem solid #333;
          border-top: 0.1rem solid transparent;
          border-bottom: 0.1rem solid transparent;
          margin-top: 0.15rem;
          margin-right: 0.12rem;
        }

      }
    }

    .con-text {
      color: #333;
      font-family: "PingFang SC";
      font-size: 0.24rem;
      font-style: normal;
      font-weight: bold;
      line-height: 0.4rem;
      /* 166.667% */
    }

    .conditions {
      display: flex;
      align-items: center;
    }

    .radio {
      margin-top: 0.12rem;
      display: flex;
      align-items: center;
      height: 0.4rem;
      cursor: pointer;

      .check-wrapper {
        width: 0.4rem;
        height: 0.4rem;
        display: flex;
        align-items: center;

        .check {
          width: 0.3em;
          height: 0.3rem;
          border-radius: 50%;
          border: 0.02rem solid #b6b6b6;
          background: #ffffff;
        }
      }

      .label {
        color: #333;
        font-family: "PingFang SC";
        font-size: 0.24rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.48rem;
        /* 200% */
      }
    }
  }

  .tooltip-wrapper {
    display: flex;
    width: 4.2rem;
  }

  .tooltip {
    display: inline-block;
    position: relative;
    font-size: 0.24rem;
  }

  .tooltip::before {
    display: block;
    content: attr(content);
    white-space: pre;
    text-align: left;
    position: absolute;
    color: #fff;
    padding: 0.08rem 0.16rem;
    border-radius: 0.02rem;
    z-index: 100;
    border-radius: 0.06rem;
    background: rgba(29, 29, 29, 0.71);
  }

  .tooltip::before {
    top: calc(100% + 0.1rem);
    left: 50%;
    transform: translate(-50%);
    box-shadow: 0 -0.05rem 0.1rem -0.02rem rgba(0, 0, 0, 0.4);
  }

  .tooltip::after {
    display: block;
    content: '';
    position: absolute;
    z-index: 100;
    width: 0;
    height: 0;
  }

  .tooltip::after {
    top: calc(100% + 0.05rem);
    left: 50%;
    transform: translate(-50%);
    border-left: 0.05rem solid transparent;
    border-right: 0.05rem solid transparent;
    border-bottom: 0.05rem solid rgba(29, 29, 29, 0.71);
  }
}
</style>
